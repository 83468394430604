// Colors
$black: #000000;
$white: #ffffff;
$purple: #9d89e5;
$light-grey: #F9FAFB;
$grey: #7E7C73;
$dark-blue: #0F0D26;
$blue : #1700E6;

$bold: 600;
$medium: 400;
$light:300;

$gradient: linear-gradient(107.52deg, #09F7E8 0%, #6DAAE7 50%, #9D89E5 100%);


@font-face {
    font-family: "Helvetica";   /*Can be any text*/
    src: url("../fonts/Helvetica.ttf") format("truetype"),
        url("../fonts/helvetica-light-587ebe5a59211.ttf") format("truetype"),
        url("../fonts/Helvetica-Bold.ttf") format("truetype");
  }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
    padding-left: 0px;
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

p{
    margin-bottom: 0;
}

img{
    width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

section {
    padding-bottom: 80px;
    h2 {
        text-align: center;
        font-size: 42px;
        line-height: 60px;
        font-weight: $medium;
        margin-bottom: 50px;
    } 
}

.custom-btn {
    display: inline-block;
    background: $gradient;
    border: none;
    font-weight: $bold;
    font-size: 20px;
    color: $white !important;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 20px;
    transition: .2s ease-in-out;

    &:hover {
        -webkit-box-shadow: 0px 10px 19px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 10px 19px -4px rgba(0,0,0,0.75);
    box-shadow: 0px 10px 19px -4px rgba(0,0,0,0.75);
    color: $white;
    }
}


body {
    font-family: "Helvetica";
    overflow-x: hidden;
}

.hero-image {
    max-width: 550px;
    margin-left: auto;
}
nav {

    .toggle {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 28px;
        height: 28px;

        @media (min-width: 991px) {
            display: none;
        }
        span{
            background: $black;
            height: 2px;
            width: 100%;
        }

        &.close {
            justify-content: center;
            span:nth-child(2){
                display: none;
            }

            span:first-child{
                transform: rotate(45deg);
            }
            span:last-child{
                transform: rotate(-45deg);
            }
        }
    }
    .navbar-brand {
        width: 200px;
    }
    .navbar-nav {
        display: flex;
        margin-left: auto;
        align-items: center;

        @media (max-width: 992px){
            align-items: flex-start;
        }
        a {
            color: $black;
            font-weight: $light;
            font-size: 16px;
            line-height: 24px;
            margin: auto 16px;
            text-decoration: none;
            
            &:hover, &.active{
                color: $purple;
            }

        }

        @media (max-width: 992px){
            display: none;
        }

        
    }
    .mobile-nav{
        display: flex;
        flex-direction: column;
        position: fixed;
        justify-content: space-between;
        top: 105px;
        left: 0px;
        right: 0px;
        background: white;
        width: 100%;
        height: calc(100% - 105px);
        padding: 20px;
.links {
    display: flex;
    flex-direction: column;
}
        a{
            margin: 10px;
        }
        .header-action {
            display: flex;
            justify-content: space-between;
            margin-left: 0px;
            width: 100%;

        }
    }

    .header-action {
        margin-left: 30px;
        a {
            color: $blue;
        }
    }
}
.hero {
    margin-top: 50px;
    h1{
        font-size: 60px;
        line-height: 72px;
        font-weight: $medium;
        color: $black;
    }
    p{
        font-weight: $light;
        font-size: 24px;
        line-height: 40px;
        margin-bottom: 30px;
        color: #7E7C73;
    }
    .custom-btn {
        @media (max-width: 550px){
            margin-bottom: 40px;
        }
    }
}

.category {

    h2 {
        margin-bottom: 70px;
    }

    .slick-slider {
        @media (max-width: 600px ){
            padding-top: 80px;
        }
    }
    .slick-prev, .slick-next {
        width: auto;
        height: auto;

        @media (max-width: 600px ){
            top: 65%;
        }
    }
    .slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
        color: inherit;
    }
    .slick-dots {
        top: -40px;
        bottom: auto;

        @media (max-width: 600px){
            display: flex !important;
            flex-direction: column;
        }
        li {
            width: auto;
            height: auto;
            

            &.slick-active {
                color: $white;
                background: linear-gradient(107.52deg, #09F7E8 0%, #6DAAE7 50%, #9D89E5 100%);
                border-radius: 20px;    
            }
            border: none;
                color: #7E7C73;
                font-weight: 300;
                font-size: 18px;
                padding: 5px 10px;
             
        }
    }
    .nav-tabs{
        display: flex;
        justify-content: center;
        border: none;

        .nav-item .nav-link {
            border: none;
            color: $grey;
            font-weight: $light;
            font-size: 18px;

            &.active {
                color: $white;
            background: linear-gradient(107.52deg, #09F7E8 0%, #6DAAE7 50%, #9D89E5 100%);
            border-radius: 20px;
            }
        }
    }
    .tab-content {
        display: flex;
        justify-content: center;
    }
    .category-wrapper{
        display: flex !important;
        background: $gradient;
        align-items: center;
        border-radius: 20px;
        padding: 30px 45px;
        height: 300px;
        @media (max-width: 600px ){
            padding: 20px;
            height: 200px;
        }
        h4 {
            color: $white;
            font-size: 24px;
            line-height: 36px;
            margin-left: 10px;

            
            @media (max-width: 600px ){
                font-size: 18px;
                line-height: 20px;
            }
        }
        img {
            width: 244px;

            @media (max-width: 600px ){
                width: 150px;
            }
        }
    }

    .sub-content {

        text-align: center;
        margin: 30px auto 50px;
        max-width: 600px;
        width: 100%;
        p{
            font-size: 24px;
            line-height: 40px;
            color: $grey;
            margin-bottom: 20px;
        }
    }
}

.slick-next {
    right: -10px;
}

.slick-prev {
    left: -10px;
}
.slick-prev:before, .slick-next:before {
    display: none;
}
.slick-arrow {        
   font-size: 36px;
   color: $black;

   &.slick-disabled {
    display: none !important;
   }
}

.slick-slide {
    padding: 0 20px;
}

.recent-works {

    // .slick-slider > div:first-child {
    //     position: absolute;
    //     top: 138px;
    //     left: -10px;
    //     z-index: 99;
    //     cursor: pointer;
    //     font-size: 36px;

    // } 

    // .slick-list + div {
    //     position: absolute;
    //     bottom: 125px;
    //     right: -10px;
    //     cursor: pointer;
    //     font-size: 36px;
    // }

    .slick-next {
        right: -10px;
    }
    
    .slick-prev {
        left: -10px;
    }
    .slick-prev:before, .slick-next:before {
        display: none;
    }
    .slick-arrow {        
       font-size: 36px;
       color: $black;

       &.slick-disabled {
        display: none !important;
       }
    }

    .slick-slide {
        padding: 0 20px;
    }


    .case-study {
        display: flex !important;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: $gradient;
        border-radius: 20px;
        height: 300px;
        max-width: 555px;

        @media (max-width: 768px){
            margin: 0;
        }
        &-logo {
            width: 100px;
            height: 100px;
            background: $white;
            border-radius: 50%;
            overflow: hidden;
        }

    }
}

.case-study-wrapper{
    a { text-decoration: none;}

    h3 {
        color: $white;
        text-transform: capitalize;
        font-size: 30px;
        margin-top: 20px;
    }
}

.brand-trust,.how-we {
    ul {
        li {
        list-style-type: none;
        border-bottom: 1px solid $grey;
        padding: 30px 0;

        &:last-child{ border: none;}
        h6{
            font-size: 20px;
            line-height: 30px;
            color: $black;
            font-weight: $bold;
            margin-bottom: 30px;
        }
         p{
            font-size: 20px;
            line-height: 30px;
            color: $grey;
            font-weight: $light;
        }
    }
    }
}

.lets-start {
    text-align: center;
}

.resources {
   padding-bottom: 0;

   h2 {
    margin-bottom: 0;
   }
    .slick-slider {
         padding: 10px 30px;
         height: 150px;
         display: flex;
         align-items: center;
    }
    .slick-slider > div:first-child {
        position: absolute;
        
        left: 0px;
        z-index: 99;
        cursor: pointer;
        font-size: 36px;

        @media (max-width: 768px){
            display: none;
        }
    } 

    .slick-list + div {
        position: absolute;
        
        right: 0px;
        cursor: pointer;
        font-size: 36px;

        @media (max-width: 768px){
            display: none;
        }
    }
}

.subscribe-form {
    display: flex;
    flex-direction: column;
    label {
        color: $white;
    }
    button {
        background: $purple;
        color: $white;
    }
}
.footer{
    padding: 40px 0;
    background: $dark-blue;

    &-heading {
        font-size: 16px;
        line-height: 30px;
        font-weight: $bold;
        color: $white;
        padding-bottom: 15px;
        text-transform: uppercase;

        @media (max-width: 768px) {
            margin-top: 20px;
        }
    }

    li,a{
        font-size: 16px;
        color: $white;
        text-decoration: none;
         padding-bottom: 15px;
    }

    a:hover {
        opacity: .8;
    }

    img {
        max-width: 30px;
        margin-right: 5px;
    }
}



// Service

.process {
    &-item{
        margin-bottom: 150px;
        img{
            max-width: 380px;
        }
        h3{
            font-size: 42px;
            line-height: 60px;
            font-weight: $medium;
            margin: 40px auto;
        }
        p{
            font-size: 24px;
            line-height: 40px;
            color: $grey;
        }
        span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 86px;
            height: 86px;
            border-radius: 50%;
            background: $gradient;
    
            p{
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 42px;
                font-weight: $medium;
                width: 84px;
                height: 84px;
                border-radius: 50%;
                background: $white;
            }
        }
    }
}

.clients {
    h6{
        color: $grey;
        font-size: 16px;
        line-height: 24px;
        font-weight: $bold;
        margin-bottom: 50px;
    }

    img {
        max-width: 120px;
    }

    .row > div {
        display: flex;
        justify-content: center;
    }
}

.our-clients{
    img{
        max-height: 106px;
        margin-bottom: 25px;
    }

    button {
        border: none;
        color: $blue;
        font-size: 20px;
        background: transparent;

        &:hover {
            color: $dark-blue;
        }
    }
}

.lets-chat {
    h2 {
        text-align: left;
    }
    a{
        background: #28B011;
            border: none;
            height: 50px;
            color: $white;
            border-radius: 50px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            font-size: 16px;
            font-weight: $bold;

            &:hover{
                background: darken(#28B011, 10%);
            }

    }
    form{
        display: flex;
        flex-direction: column;

        input {
            height: 50px;
            border: 1px solid #BBC4C2;
            border-radius: 12px;
            padding: 0 20px;
            margin-top: 15px;
        }

        .react-tel-input {
            margin-top: 15px;
            .form-control{
                height: 50px;
                width: 100%;
                border-radius: 12px;
            }

            .flag-dropdown {
                border-radius: 12px 0 0 12px;
            }
        }

        button {
            background: $gradient;
            border: none;
            height: 50px;
            color: $white;
            border-radius: 50px;
            font-size: 16px;
            font-weight: $bold;
            margin-bottom: 10px;
            margin-top: 20px;
            
            &:hover{
                box-shadow: 0px 10px 19px -4px rgb(0 0 0 / 75%);
            }
        }
    }
}


.success {
    position: relative;
    opacity: 0.2;

    &-msg {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100% - 50px);
        width: 100%;
    }
}
.all-blogs {
    img{
        height: 260px;
        object-fit: cover;
    } 
}
.blog {
    
    &-recent {
        display: flex;
        margin-top: 30px;
        
        .blog-img{
            img{
                width: 200px;
                height: 115px;
                object-fit: cover;
            }
          

            & + div {
                margin-left: 5px;
            }
        }
        .blog-title {
            font-size: 25px;
            margin-top: 0px;
        }

        .blog-category, .blog-created {
            margin: 0;
        }
    }
    .isFeatured {
        text-transform: uppercase;
        margin-bottom: 10px;
    }
    &-tile {
        a {
            text-decoration: none !important;
        }

        img{
            border-radius: 20px;
            margin-bottom: 10px;
        }
    }
    &-category {
        color: $grey;
        text-transform: capitalize;
    }
    &-title {
        font-size: 42px;
        color: $black;
        margin: 10px 0;
    }
    &-featured {
        margin-bottom: 30px;
        padding-bottom: 30px;
    }
    &-seperator {
        border: 1px solid $grey;
        margin: 50px 0;
    }
    &-created {
        color: $grey;
    }

    &-detail {
        &-path{
            text-align: center;
            padding: 30px;
            background: $light-grey;
            margin-bottom: 20px;
        }

        &-heading h1{
            font-size: 42px;
        }
        &-image img{
           border-radius: 20px;
        }
        &-description {
            margin: 10px 0;

            p{
                font-size: 18px;
                line-height: 24px;
                color: $grey;
            }
        }

        &-share{

            h3 {
                font-size: 42px;
                margin: 40px 0;
                font-weight: 400;
            }
            ul{
                display: flex;
                align-items: center;
                justify-content: center;
                li button{
                    margin: 5px;
                }
            }
            img{
                max-width: 60px;
            }
        }
    }
}


// Dashboard

.stepper-form {
    padding: 60px 0;
}

.form-header-content {
    span{
        color: #BBC4C2;
    }
    h2{
        padding: 20px 0 30px 0;
    }
    h6 {
        padding-bottom: 10px;
    }
}
form {
    display: flex;
    flex-direction: column;

    input {
        height: 50px;
        width: 100%;
        border: 1px solid #BBC4C2;
        border-radius: 12px;
        padding: 0 20px;
        margin-top: 15px;
    }
    .custom-btn {
        margin-top: 15px;
    }
    .react-tel-input {
        margin-top: 15px;

        .form-control {
            height: 50px;
            width: 100%;
            border-radius: 12px;
        }

        .flag-dropdown {
            border-radius: 12px 0 0 12px;
        }
    }

    button.formik-s-btn {
        background: $gradient;
        border: none;
        height: 50px;
        padding: 0 50px;
        color: $white;
        border-radius: 50px;
        font-size: 16px;
        font-weight: $bold;
        margin-bottom: 10px;
        margin-top: 20px;

        &:hover {
            box-shadow: 0px 10px 19px -4px rgb(0 0 0 / 75%);
        }
    }
}

.stepper-form-heading {
    font-size: 40px;
    font-weight: $bold;
}

.estimation {
    display: flex;
    flex-direction: column;

    label {
        position: relative;
        display: flex;
        align-items: center;
        height: 70px;
        padding: 10px;
        border: 2px solid $grey;
        margin-bottom: 10px;
        border-radius: 12px;

        &.selected {
            border-color: $blue;
        }
    }

    input {
        height: auto;
        position: absolute;
        margin-bottom: 0;
        visibility: hidden;
    }
}


.form-meme {

    .input_group {
        display: flex;
        align-items: center;
        height: 70px;
        padding: 10px 70px 10px 10px;
        border: 2px solid $grey;
        margin-bottom: 10px;
        border-radius: 12px;

    }

    .no_floating.no_inline {
        display: flex;
        width: 100%;
        flex-direction: row !important;
        align-items: center !important;
        justify-content: space-between !important;

        input {
            width: 74px;
            padding: 5px;
            border: none;
            border-bottom: 2px solid $grey;
            border-radius: 0px;
        }
    }
}


.form-goals {
    display: flex;
    flex-direction: column;

    label {
        position: relative;
        display: flex;
        align-items: center;
        height: 70px;
        padding: 10px;
        border: 2px solid $grey;
        margin-bottom: 10px;
        border-radius: 12px;

        &.selected {
            border-color: $blue;
        }
    }

    input {
        height: auto;
        position: absolute;
        margin-bottom: 0;
        visibility: hidden;
    }

}

.form-campaign {
    .input_group {
        margin-bottom: 0;
    }
    .no_floating.no_inline input,
    select,
    textarea {
        position: relative;
        display: flex;
        align-items: center;
        height: 70px;
        padding: 10px;
        width: 100%;
        border: 2px solid $grey;
        margin-bottom: 10px;
        border-radius: 12px;
    }

    textarea {
        height: 140px;
    }
}

.form-logo {
    p {
        margin-bottom: 0;
    }
    &>div {
        display: flex;
        margin-bottom: 10px;
    }

    label {
        display: flex;
        align-items: center;
        margin-right: 20px;

        input {
            height: auto;
            margin-bottom: 0;
            margin-right: 5px;
            margin-top: 0;
        }
    }

    textarea {
        position: relative;
        display: flex;
        align-items: center;
        height: 140px;
        padding: 10px;
        width: 100%;
        border: 2px solid $grey;
        margin-bottom: 10px;
        border-radius: 12px
    }
}

.toast-container{
    position: fixed !important;
    top: 0;
    left: 50%;
    transform: translateX(-50%) !important;
}
form {
    &>div:last-child {
        max-width: 650px;
        width: 100%;
        justify-content: center;
        /* text-align: center; */
        margin: 0 auto;
    }
}

.modal {
    .file-upload {
        display: flex;
        align-items: center;

        input {
            height: auto;
            border: none;
            width: auto;
        }
    }
}
.file-upload {
    display: flex;
    justify-content: space-between;
    img{
        max-width: 200px;
        margin-bottom: 10px;
    }
    input {
         border: none; 
        border-radius: 0; 
        padding: 0 ; 
        margin-top: 0; 
    }
}


.dashboard {
    .signOut{
       cursor: pointer;
    }
    
    .navbar {
        display: none;
    }
    &-nav {
        background: #E9F4F2;
        padding: 20px;
        position: fixed;
        width: 300px;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        ul {
            margin-top: 50px;

            li {
                margin-bottom: 10px;
                a {
                    color: $white;
                    text-decoration: none;
                    padding: 10px 20px;
                    font-weight: $bold;
                    font-size: 12px;
                    color: $black;
                    text-decoration: none;
                    width: 100%;

                    &.active {
                        display: inline-block;
                        background: $gradient;
                        color: $white;
                        border: none;
                        border-radius: 20px;
                        transition: .2s ease-in-out;

                        &:hover {
                            -webkit-box-shadow: 0px 10px 19px -4px rgba(0, 0, 0, 0.75);
                            -moz-box-shadow: 0px 10px 19px -4px rgba(0, 0, 0, 0.75);
                            box-shadow: 0px 10px 19px -4px rgba(0, 0, 0, 0.75);
                            color: $white;
                        }
                    }
                }
            }
        }

        .custom-btn {
            display: inline-block;
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid $black;
        margin-bottom: 50px;
    }

    &-welcome {
        h3{
            font-size: 45px;
        }

        p{
            color: #7E7C73;
        }
        
    }

    &-name {
        display: flex;
        align-items: baseline;

        img{
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }

        h5{
            font-size: 24px;
            margin-bottom: 0px;
        }
    }


    &-content {
        padding: 30px;
        margin-left: 300px;
    }

    &-count {
        display: flex;
        padding: 20px;
        flex-direction: column;
        justify-content: space-between;
        height: 150px;
        border: 1px solid $grey;
        border-radius: 10px;
        margin-bottom: 20px;

        &-content {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &.status {
                p{
                    font-size: 16px;
                    color: #003970;
                }
                a{
                    padding: 8px 20px;
                    font-size: 14px;
                    cursor: pointer;
                }
            }
            p {
                font-size: 40px;
                color: $black;
                margin-bottom: 0px;
            }
        }

        &-heading p {
            color: #7E7C73;
            font-size: 18px;
        }
    }
}

@media (max-width: 1024px) {
    .dashboard-nav {
        display: none;
    }
    .dashboard .navbar {
        display: flex;
        margin-bottom: 20px;
    }
    .dashboard-content {
        margin-left: 0px;
        padding: 0;
    }

    .dashboard .signOut{
        display: none;
    }
}

.campaign-table {
    td {
        word-break: break-all;
    }
}

.project-item {
    border: 1px solid $grey;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;

    h2 {
        text-align: left;
    }

    p {
        margin-bottom: 0;
    }

    &-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.hide-outer-logo {
    .logo-outer{
        display: none;
    }
}

.form-heading {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
    h2 {
        margin-bottom: 0;
        font-weight: $bold;
    }

    p {
        color: #7E7C73;
    }
}   

.modal {
    ul {
        padding-left: 0;

        li {
            padding-bottom: 10px;
            display: flex;
            .modal-key {
                padding-right: 10px;
            }

            span:last-child {
                word-break: break-all;
            }
        }
    }
}